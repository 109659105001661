<template>
  <div class="container">
    <div class="card  p-md-5">
      <table class="table">
        <thead class="">
          <tr>
            <th scope="col">النتيجة</th>ؤ
            <th scope="col">طلاقة الطالب</th>
            <th scope="col">اصالة الطالب</th>
            <th scope="col">مرونة الطالب</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>المجموع الكلى</td>
            <td>{{ studentFluency }} / 26</td>
            <td>{{ studentScore }} / 140</td>
            <td>{{ category_count }} / 16</td>
          </tr>
          <tr>
            <td>النسبة المئوية</td>
            <td>{{ (studentFluency * 100) / 26 }} %</td>
            <td>{{ (studentScore * 100) / 140 }} %</td>
            <td>{{ (category_count * 100) / 16 }} %</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <router-link to="/exams" style="width: 100%">
        <button v-bind:disabled="disabled2" class="btn btn-primary">
          العودة
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "scoreReview",
  data() {
    return {
      studentScore: null,
      studentFluency: null,
      category_count: null,
      studentData: {}
    };
  },
  mounted() {
    this.studentData = {
      student_id: this.$route.params.id,
      course_id: 1
    };

    this.$store
      .dispatch("groups/getStudentScore", this.studentData)
      .then(response => {
        this.studentScore = response.total;
        this.studentFluency = response.fluency;
        this.category_count = response.category_count;
      });
  }
};
</script>
